@charset 'UTF-8';

@use './color' as *;



// border color
$burger-border-color: $gray-800;

// bar の太さ
$burger-border-thickness: 2px;

// bar の長さ
$burger-border-width-xs: 24px !default;
$burger-border-width-sm: 24px !default;
$burger-border-width-md: 24px !default;
$burger-border-width-lg: 24px !default;
$burger-border-width-xl: 24px !default;
$burger-border-width: (
  xs: $burger-border-width-xs,
  sm: $burger-border-width-sm,
  md: $burger-border-width-md,
  lg: $burger-border-width-lg,
  xl: $burger-border-width-xl
) !default;

// bar 同士の間隔
$burger-border-gutter-xs: 4px !default;
$burger-border-gutter-sm: 4px !default;
$burger-border-gutter-md: 4px !default;
$burger-border-gutter-lg: 4px !default;
$burger-border-gutter-xl: 4px !default;
$burger-border-gutters: (
  xs: $burger-border-gutter-xs,
  sm: $burger-border-gutter-sm,
  md: $burger-border-gutter-md,
  lg: $burger-border-gutter-lg,
  xl: $burger-border-gutter-xl
) !default;

// burger open 時に付与するクラス名
$burger-open-class: '.visible-mobile-menu';
