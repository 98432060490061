@charset 'UTF-8';

@use '../global/' as *;



// Construct layout
.construct-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.construct-header {
  background-color: transparent;
  flex: none;
  width: 100%;
  z-index: $z-index-header;
}

.construct-nav {
  background-color: transparent;
  flex: none;
  position: absolute;
  top: $header-height-xs;
  left: 0;
  transform: translateY(-100%);
  transition: transform $base-transition;
  width: 100%;
  z-index: $z-index-nav;
  // OPEN
  #{$burger-open-class} & {
    box-shadow: $base-box-shadow;
    transform: translateY(0);
  }
  @include media(lg){
    box-shadow: none;
    position: static;
    top: 0;
    transform: translateY(0);
  }
}

.construct-main {
  background-color: transparent;
  flex: 1 0 auto;
  width: 100%;
  z-index: $z-index-main;
}

.construct-footer {
  background-color: transparent;
  flex: none;
  width: 100%;
  z-index: $z-index-footer;
}



// Container
.container {
  @include makeContainer;
}



// section-block
.section-block {
  & + & {
    @each $key, $value in $breakpoints {
      @include media($key, true){
        margin-top: map-get($section-block-gutters, $key);
      }
    }
  }
}
