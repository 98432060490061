@charset 'UTF-8';

@use '../global/' as *;
@use 'sass:math';



// 子メニューの背景色
$child-background-color: #d9e1ee;



// wrapper
.global-nav-wrapper {
  background-color: $base;
  @include media(lg){
    background-color: $base;
  }
}

// container
.global-nav-container {
  @include makeContainer();
}

// navigation
ul.global-nav {
  display: block;
  position: relative;
  @include media(lg){
    display: flex;
    justify-content: space-between;
    > li {
      border-left: 1px solid $white;
      &:last-child {
        border-right: 1px solid $white;
      }
    }
  }
}

// メインメニュー
li.gnav-parent {
  @include media(lg){
    flex: 0 0 math.percentage( math.div(1, 3) );
  }
}

a.gnav-local-link {
  background-color: $white;
  color: $gray-dark;
  display: block;
  font-size: .857142rem;
  font-weight: 700;
  padding: 1.25em $base-side-padding-xs;
  transition: background-color $base-transition;
  @media (hover: hover){
    &:hover {
      background-color: $child-background-color;
      color: $base;
      text-decoration: none;
    }
  }
  .active & {
    background-color: $base;
    color: $white;
    span {
      &::after {
        background-image: set-svg($caret-right, $white);
        transform: rotate(90deg);
      }
    }
  }
  // ボーダーと Caret の処理
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &::before {
      content: '';
      background-color: #ccc;
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: -1.25em;
    }
    &::after {
      content: '';
      background-image: set-svg($caret-right, $base);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      width: 1rem;
      height: 1rem;
    }
  }
  @include media(lg){
    background-color: $base;
    color: $white;
    font-size: .9375rem;
    line-height: 1;
    padding: 1.133333em 0;
    text-align: center;
    span {
      display: inline;
      &::before, &::after {
        content: none;
      }
    }
    .hover & {
      background-color: $child-background-color;
      color: $base;
    }
  }
}



// 子メニュー
//
.gnav-child-wrapper {
  background-color: #f7f6f7;
  max-height: 0;
  overflow: hidden;
  transition: max-height $base-transition;
  @include media(lg){
    position: absolute;
    left: 0;
    width: 100%;
    background: $child-background-color;
    transition: max-height $base-transition .2s;
  }
}

.gnav-child-container {
  @include media(lg){
    background-color: transparent;
    display: flex;
    margin: 0 auto;
    padding: 1rem map-get( $base-side-padding, lg );
    max-width: get_max_width( lg );
  }
  @include media(xl){
    margin: 0 auto;
    padding: 1rem map-get( $base-side-padding, xl );
    max-width: get_max_width( xl );
  }
}

.gnav-child-inner {
  @include media(lg){
    padding: 1rem;
    @for $i from 1 through $grid-columns {
      &.col-#{$i} {
        width: percentage( math.div($i, 12) );
      }
    }
  }
}

ul.gnav-child-list {
  font-size: .857142rem;
}

a.gnav-child-link {
  color: $gray-dark;
  display: flex;
  align-items: center;
  padding: .5em $base-side-padding-xs;
  position: relative;
  // Caret
  &::before {
    content: '';
    background-image: set-svg($caret-right, $base);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: .25em;
    width: 1rem;
    height: 1rem;
  }
}
