@charset 'UTF-8';

@use './color' as *;



// font size
$btn-font-size-normal: 1rem !default;
$btn-font-size-small:  $btn-font-size-normal * .8125 !default;
$btn-font-size-large:  $btn-font-size-normal * 1.25 !default;

$btn-font-sizes: (
  'small':  $btn-font-size-small,
  'normal': $btn-font-size-normal,
  'large':  $btn-font-size-large
) !default;



// border 太さ
$btn-border-width: 1px !default;



// padding 左右
$btn-x-padding-small:  .875em !default;
$btn-x-padding-normal: 1em !default;
$btn-x-padding-large:  1.125em !default;

$btn-x-paddings: (
  'small':  $btn-x-padding-small,
  'normal': $btn-x-padding-normal,
  'large':  $btn-x-padding-large
) !default;



// padding 上下
$btn-y-padding-small:  .5em !default;
$btn-y-padding-normal: .625em !default;
$btn-y-padding-large:  .625em !default;

$btn-y-paddings: (
  'small':  $btn-y-padding-small,
  'normal': $btn-y-padding-normal,
  'large':  $btn-y-padding-large
) !default;



// background color
$btn-background-colors: (
  'accent': $accent,
  'uno'   : $uno,
  'dos'   : $dos,
  'tres'  : $tres,
  'cuatro': $cuatro,
  'cinco' : $cinco
) !default;

$btn-hover-background-colors: (
  'accent': lighten( $accent, 7.5% ),
  'uno'   : lighten( $uno, 7.5% ),
  'dos'   : lighten( $dos, 7.5% ),
  'tres'  : lighten( $tres, 7.5% ),
  'cuatro': lighten( $cuatro, 7.5% ),
  'cinco' : lighten( $cinco, 7.5% )
) !default;

// text color
$btn-colors: (
  'accent': $white,
  'uno'   : $white,
  'dos'   : $white,
  'tres'  : $white,
  'cuatro': $white,
  'cinco' : $white
) !default;

$btn-hover-colors: (
  'accent': $white,
  'uno'   : $white,
  'dos'   : $white,
  'tres'  : $white,
  'cuatro': $white,
  'cinco' : $white
) !default;
