@charset 'UTF-8';

@use '../global/' as *;



.footer-wrapper {
  background-color: $gray-100;
  border-bottom: 1px solid $gray-200;
  position: relative;
}



.footer-container {
  @include makeContainer;
}



.footer-inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}



.footer-inner-left {
  flex: 1 1 0;
}



.footer-inner-center {
  display: flex;
  justify-content: center;
  flex: 1 1 0;
}



.footer-inner-right {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 0;
}



.footer-brand {
  font-weight: 700;
  a {
    color: $gray-dark;
    @media(hover: hover){
      &:hover {
        color: $gray-600;
      }
    }
  }
}
