@charset 'UTF-8';

@use './color' as *;



// フォントサイズ
$form-font-size: 16px;

// input の padding
$input-x-padding: 1em;
$input-y-padding: .8em;

// form-field 同士の余白
$form-field-gutters: (
  xs: 2rem,
  sm: 2rem,
  md: 2rem,
  lg: 2rem,
  xl: 2rem
) !default;

// form-grid のカラム数
$form-grid-columns: 12 !default;

// グリッド要素同士の空白
$form-grid-gutters: (
  xs: 4px,
  sm: 4px,
  md: 4px,
  lg: 4px,
  xl: 4px
) !default;

// フォームで使用するボタン
$form-btn-border       : 2px;
$form-btn-border-radius: 2em;
$form-btn-font-size    : 16px;
$form-btn-height       : 44px;
$form-btn-padding      : 16px;
$form-btn-gutter       : 8px;



// Component
//

// ボーダーの色
$form-border-color: $gray-400;

// ボーダー
$base-form-border: 1px solid $form-border-color;

// 角丸
$form-border-radius: 4px !default;

// チェックボックス & ラジオボタンのマージン
$form-radio-checked-gutter: .5rem;

// タイトルのバックグラウンドカラー
$form-title-background-color: #e0f1f5;

// 項目のバックグラウンドカラー
$form-value-background-color: $white;

// ボーダーのアニメーションの太さ
$border-animation-thickness: 1px;

// ボーダーのアニメーションで変化するボーダー色
$border-animation-color: $base;

// form 要素をフォーカスしたときに付与するクラス名
$form-focus-in-class-name: '.focus-in';
$form-focus-fixed-class-name: '.focus-fixed';

// プレースホルダーの色
$placeholder-color: #ced4da;

// プレースホルダーの変化する色
$placeholder-after-color: #339966;

// エラーの色
$form-error-color: $red;
$form-error-background-color: #FFDFD8;
