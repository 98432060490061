@charset 'UTF-8';
@use '../global/' as *;
@use 'sass:math';



// iOSのフォーカス時のズームを無効にする
//
.form {
  input[type='text'],
  input[type='radio'],
  input[type='checkbox'],
  textarea {
    font-size: $form-font-size;
  }
}



// input & textarea
//
.input {
  background-color: $white;
  border: $base-form-border;
  border-radius: $form-border-radius;
  box-shadow: none;
  color: $gray-dark;
  font-size: 1em;
  line-height: 1;
  padding: $input-y-padding $input-x-padding;
  width: 100%;
  transition: border-color $base-transition, background-color $base-transition;

  // プレースホルダー
  &::placeholder {
    font-size: .875em;
    transition: color $base-transition;
  }
  &::-moz-placeholder {
    color: $gray-600;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $gray-600;
  }
  &::-webkit-input-placeholder {
    color: $gray-600;
  }
  &::-ms-expand {
    background: $white;
    border: 0;
  }

  // File input
  &[type="file"] {
    overflow: hidden;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
      font-weight: normal;
    }
  }

  &::file-selector-button {
    @include file-selector-button();
  }
  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $gray-200;
  }

  &::-webkit-file-upload-button {
    @include file-selector-button();
  }
  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $gray-200;
  }

  // フォーカス
  &:focus {
    background-color: $white;
    border-color: $base;
    box-shadow: 0 0 6px 1px rgba( $base, .075 );
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }

  // readonly 属性の INPUT 要素
  &:read-only {
    font-weight: 700;
    // フォーカス
    &:focus {
      border-color: $base;
      &::placeholder {
        color: transparent;
      }
    }
  }

}



// textarea
//
textarea {
  line-height: 1.6;
  height: auto;
  padding: 1em;
  resize: vertical;
  width: 100%;
}



// select
//
select.select {
  background-color: $white;
  background-image: set-svg($caret-down, $gray-dark);
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  border: $base-form-border;
  border-radius: $form-border-radius;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: $form-font-size;
  outline: none;
  padding: .75em 3rem .75em 1rem;
  position: relative;
  transition: border-color $base-transition;
  width: auto;
  &::-moz-focus-inner {
    border: 0;
  }
}



// radio button & checkbox
//

input[type='checkbox'],
input[type='radio'] {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  margin: 0;
  outline: none;
}

.checkbox-group,
.radio-group {
  display: inline-block;
  margin: $form-radio-checked-gutter * -1;
  // 横並び
  &.row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}

.label-checkbox,
.label-radio {
  //cursor: pointer;
  display: flex;
  padding: $form-radio-checked-gutter;

  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &::before {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      margin-right: .25em;
      width: 1.25em;
      height: 1.25em;
    }
  }
}

.label-checkbox {
  input[type="checkbox"] {
    + label {
      &::before {
        background-image: set-svg($checkbox, $gray-dark);
      }
    }
    &:checked + label {
      &::before {
        background-image: set-svg($checkbox-checked, $base);
      }
    }
  }
}

.label-radio {
  input[type="radio"] {
    + label {
      &::before {
        background-image: set-svg($radio, $gray-dark);
      }
    }
    &:checked + label {
      &::before {
        background-image: set-svg($radio-checked, $base);
      }
    }
  }
}



// label
//
label.item {
  border-left: 6px solid $base;
  color: $gray-700;
  display: block;
  font-size: .875em;
  font-weight: 700;
  margin-bottom: .8em;
  padding-left: .6em;
}



// バリデートエラー表示用
//
.validate {
  display: none;
  margin-top: .25em;
  &.valid {
    display: inline-block;
  }

  .error {
    animation: fadeIn $base-transition;
    background-color: $red;
    border-radius: 4px;
    box-shadow: $base-box-shadow-small;
    color: $white;
    display: inline-block;
    font-size: .875em;
    font-weight: 700;
    padding: .25em 1em;
    position: relative;
  }

  // label の横（内）にあるタイプ
  &.label-inside {
    display: inline-block;
    font-size: 1.125em;
    margin-bottom: 0;
    margin-left: .5em;
    .error {
      margin-top: 0;
      &:before {
        content: none;
      }
    }
  }
}



/**
*** Layout
*** ---------- ---------- --------- ---------- ---------- */

// 各項目の間隔
//
.form-field {
  & + & {
    @each $key, $value in $breakpoints {
      @include media($key){
        margin-top: map-get($form-field-gutters, $key);
      }
    }
  }
}



// Button wrapper
//
.btn-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  .btn-column {
    flex: 0 0 auto;
  }

  // 中央
  &.justify-center {
    justify-content: center;
  }
  // 右揃え
  &.justify-right {
    justify-content: right;
  }
}



.input-parent {
  display: flex;
  align-items: center;
  position: relative;
}



// Border Input
.border-input {
  background-color: $white;
  border-bottom: $border-animation-thickness solid $form-border-color;
  box-shadow: none;
  color: $gray-dark;
  font-size: $form-font-size;
  line-height: 1;
  padding: 1.125em 0 6px;
  width: 100%;
  transition: border-color $base-transition, background-color $base-transition;

  // プレースホルダー
  &::placeholder {
    font-size: .875em;
    transition: color $base-transition;
  }
  &::-moz-placeholder {
    color: $gray-600;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $gray-600;
  }
  &::-webkit-input-placeholder {
    color: $gray-600;
  }
  &::-ms-expand {
    background: $white;
    border: 0;
  }

  // File input
  &[type="file"] {
    overflow: hidden;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &::file-selector-button {
    @include file-selector-button();
  }
  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $gray-200;
  }

  &::-webkit-file-upload-button {
    @include file-selector-button();
  }
  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $gray-200;
  }

  // フォーカス
  &:focus {
    background-color: $white;
    border-color: $base;
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }

  // テキストエリア
  &.textarea {
    border: $border-animation-thickness solid $form-border-color;
    line-height: 1.6;
    padding: 1em;
    &:focus {
      background-color: $white;
      border-color: $base;
      box-shadow: 0 0 6px 1px rgba( $base, .075 );
      outline: 0;
      &::placeholder {
        color: transparent;
      }
    }
  }
}



// border アニメーション
.border-animation {
  &::after {
    background: $border-animation-color;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $border-animation-thickness;
    z-index: 10;
    transform-origin: center;
    transform: scaleX(0);
    transition: all .3s ease;
  }
  #{$form-focus-in-class-name} &,
  #{$form-focus-fixed-class-name} & {
    &::after {
      transform: scaleX(1);
    }
  }
  &._textarea {
    &::after {
      bottom: 4px;
      @include media(lg){
        bottom: 6px;
      }
    }
  }
}



// Placeholder
.placeholder-item {
  color: $placeholder-color;
  font-size: .875em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transform-origin: 0 50%;
  transition: .2s;

  #{$form-focus-in-class-name} &,
  #{$form-focus-fixed-class-name} & {
    color: $placeholder-after-color;
    font-weight: 700;
    top: -8px;
    transform: scale(.8);
  }
}



// カレンダーアイコンの表示
.icon-calendar {
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  &::before {
    content: '';
    background-image: set-svg($calendar, $gray-400);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 20px;
    height: 20px;
  }
}



// カレンダーの入力値を削除するボタン
.delete-calendar {
  background-image: set-svg($remove, $gray-600);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  width: 20px;
  height: 20px;
  transition: background $base-transition;
  @media(hover:hover){
    &:hover {
      background-image: set-svg($remove, $gray-400);
    }
  }
}
