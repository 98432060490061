@charset 'UTF-8';

@use '../global/' as *;



.btn {
  background-color: $base;
  border: $btn-border-width solid transparent;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $btn-font-size-normal;
  font-weight: 500;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: background-color $base-transition, color $base-transition;
  vertical-align: middle;
  white-space: nowrap;
  padding: $btn-y-padding-normal $btn-x-padding-normal;

  // Sizes
  @each $name, $value in $btn-font-sizes {
    &.#{$name} {
      font-size: $value;
      padding: map-get( $btn-y-paddings, $name ) map-get( $btn-x-paddings, $name );
    }
  }

  // hover
  @media(hover: hover){
    &:hover {
      background-color: lighten( $base, 7.5% );
      color: $white;
    }
  }

  // Colors variation
  @each $name, $value in $btn-background-colors {
    &.#{$name} {
      background-color: $value;
      color: map-get( $btn-colors, $name );
      &:hover {
        background: map-get( $btn-hover-background-colors, $name );
        color: map-get( $btn-hover-colors, $name );
      }
    }
  }

  // Outline
  &.outline {
    background-color: $white;
    border: $btn-border-width solid $base;
    color: $base;
    // Outline hover
    @media(hover: hover){
      &:hover {
        background-color: $base;
        color: $white;
      }
    }
    // Outline colors
    @each $name, $value in $btn-background-colors {
      &.#{$name} {
        border: $btn-border-width solid $value;
        color: map-get( $theme-colors, $name );
        &:hover {
          background-color: map-get( $btn-hover-background-colors, $name );
          color: map-get( $btn-hover-colors, $name );
        }
      }
    }
  }

  // Icon
  span {
    display: inline-flex;
    align-items: center;
    @each $name, $icon in $svg-icon-types {
      &.#{$name} {
        &::after {
          content: '';
          @include setSvgIcon($icon, $white, 1rem);
          margin-left: .75rem;
        }
        &.left {
          &::after {
            content: none;
          }
          &::before {
            content: '';
            @include setSvgIcon($icon, $white, 1rem);
            margin-right: .75rem;
          }
        }
      }
      // icon color
      @each $key, $color in $all-colors {
        &.ic-#{$key} {
          &::after, &::before {
            background-image: set-svg($icon, $color);
          }
        }
      }
    }
  }

  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

}
