@charset 'UTF-8';

@use '../global/' as *;



ul.list,
ol.list {

  li {
    &:nth-of-type(n+2){
      margin-top: .8em;
    }
  }

  ul, ol {
    margin-left: 1em;
  }

  &.li-mb {
    li:nth-of-type(n+2) {
      margin-top: .8em;
    }
    ul, ol {
      margin-top: .8em;
    }
  }

  // インデント
  &.indent {
    margin-left: 1em;
    ul, ol {
      margin-top: .8em;
    }
  }
}



ul.list {
  // dotted
  &.disc {
    list-style: disc outside;
    padding-left: 1.125em;
    ul {
      list-style: circle outside;
    }
  }
  // 四角 & 丸
  &.square,
  &.circle {
    li {
      line-height: inherit;
      padding-left: 1.25em;
      position: relative;
      &:before {
        content: '';
        color: inherit;
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.square {
    li {
      &:before {
        content: '■';
      }
    }
  }

  &.circle {
    li {
      &:before {
        content: '●';
      }
    }
  }
}



ol.list {
  // 数字
  &.num {
    list-style: decimal outside;
    padding-left: 1.125em;
    ol {
      list-style: decimal outside;
    }
  }
}



dl.list {
  dt {
    font-weight: 700;
    &:nth-of-type(n+2) {
      margin-top: 1em; }
  }
  dd {
    line-height: 1.4;
  }
}



// list の横並び
//
.list-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  li {
    &:not(:last-child){
      margin-right: 1em;
    }
  }
}
