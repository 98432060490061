@charset 'UTF-8';

@use '../global/' as *;



// variable
//
$pagetop-active-class-name: '.pagetop-active';



.pagetop {
  background-color: $white;
  border: 1px solid $base;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: all $base-transition;
  width: 38px;
  height: 38px;
  z-index: $z-index-pagetop;
  // 矢印
  &:after {
    content: '';
    background-image: set-svg($arrow-up, $base);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1rem;
    height: 1rem;
  }

  @include media(lg) {
    bottom: 2rem;
    right: 2rem;
    width: 52px;
    height: 52px;
  }

  &#{$pagetop-active-class-name} {
    opacity: 1;
    visibility: visible;
  }
}
