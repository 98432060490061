@charset 'UTF-8';

@use '../global/' as *;



.burger-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  position: relative;
}



.burger-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    background-color: $burger-border-color;
    display: block;
    width: 100%;
    height: $burger-border-thickness; // 太さ
    transition: transform $base-transition, opacity $base-transition;
  }
  @each $key, $value in $burger-border-width {
    @include media($key, true){
      width: $value;
      // ボーダー同士の間隔
      span:nth-of-type(2) {
        margin: map-get($burger-border-gutters, $key) 0;
      }
    }
  }

  // OPEN
  #{$burger-open-class} & {
    background-color: transparent;
    span {
      &:nth-of-type(1) {
        transform: translateY(6px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-6px) rotate(45deg);
      }
    }
    @include media(md) {
      span {
        &:nth-of-type(1) {
          transform: translateY(6px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
    @include media(lg) {
      span {
        &:nth-of-type(1) {
          transform: translateY(6px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
  }
}
