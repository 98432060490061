@charset 'UTF-8';

@use '../global/' as *;



.header-wrapper {
  background-color: $white;
  box-shadow: $base-box-shadow;
  position: relative;
}



.header-container {
  @include makeContainer;
}



.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @each $key, $value in $header-height {
    @include media($key, true){
      height: $value !important;
    }
  }
}



.header-inner-left {
  flex: 1 1 0;
}



.header-inner-center {
  display: flex;
  justify-content: center;
  flex: 1 1 0;
}



.header-inner-right {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 0;
}



.header-brand {
  font-weight: 700;
  a {
    color: $gray-dark;
    @media(hover: hover){
      &:hover {
        color: $gray-600;
      }
    }
  }
}
