@charset 'UTF-8';

@use '../global/' as *;



// variable
$accordion-open-class-name: '.active';



.accordion-wrapper {
  width: 100%;
}



.accordion {
  position: relative;
}



.accordion-item {
  border: 1px solid $base;
  overflow: hidden;

  &:first-of-type {
    border-top-right-radius: $base-border-radius;
    border-top-left-radius: $base-border-radius;
  }
  &:last-of-type {
    border-bottom-right-radius: $base-border-radius;
    border-bottom-left-radius: $base-border-radius;
  }
  &:not(:first-of-type) {
    border-top: none;
  }
}



.accordion-heading {
  background-color: $base;
  border-bottom: 1px solid darken( $base, 5% );
  color: $white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  outline: none;
  margin: 0;
  padding: 1em;
  position: relative;
  transition: background-color $base-transition;
  width: 100%;

  @media(hover: hover){
    &:hover {
      background-color: darken( $base, 5% );
    }
  }

  // arrow
  &:after {
    content: '';
    @include setSvgIcon( $circle-plus, $white, 1.25em );
  }

  // open
  &#{$accordion-open-class-name} {
    &:after {
      @include setSvgIcon( $circle-minus, $white, 1.25em );
    }
  }
}



.accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height $base-transition;
}

.accordion-contents-inner {
  background-color: $white;
  padding: 1em;
}
