@charset 'UTF-8';
@use './module/global/' as *;



// Hero
.hero-wrapper {
  width: 100%;
  height: 60vw;
  @include media(md){
    height: 47vw;
  }
}

.hero-slider-container {
  width: 100%;
  height: 100%;
}

.slider-background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: inherit;
}

.slider-headline {
  color: $white;
  font-size: 3.6vw;
  font-weight: 700;
  letter-spacing: .1rem;
  position: absolute;
  top: 1em;
  left: 1em;
  text-shadow: .05em .05em .2rem $base;
  z-index: 1;
}
